<template>
  <div>

  </div>
</template>

<script>
export default {
  name: "Close",

  mounted() {
    this.sendToParentMessage();
  },

  methods: {
    sendToParentMessage() {
				let data = "close";
        //window.parent.postMessage({ action: "close"}, "*");
				window.parent.postMessage({"action": data}, '*');
        console.log("sendToParentMessage");
			}
  },
}
</script>

<style scoped>

</style>
